var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updatePassword)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('h3',{staticClass:"mb-0 font-weight-normal"},[_vm._v(" Change Password ")])])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Old Password","label-for":"old-password"}},[_c('ValidationProvider',{attrs:{"vid":"oldPassword","name":"Old Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"old-password","type":"password","placeholder":"Enter Your Old Password"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('span',{staticClass:"invalid-feedback d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"New Password","label-for":"new-password"}},[_c('ValidationProvider',{attrs:{"vid":"newPassword","name":"New Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"new-password","type":"password","placeholder":"Enter Your New Password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('span',{staticClass:"invalid-feedback d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Confirm Password","label-for":"confirm-password"}},[_c('ValidationProvider',{attrs:{"vid":"confirmPassword","name":"Confirm Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirm-password","type":"password","placeholder":"Enter Your Confirm Password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('span',{staticClass:"invalid-feedback d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Change ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }