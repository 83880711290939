<template>
  <div>
    <b-card>

      <!-- form -->
      <ValidationObserver
        ref="form"
        v-slot="{ handleSubmit }"
      >
        <b-form
          class="mt-1"
          @submit.prevent="handleSubmit(updatePassword)"
        >
          <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-center mb-3">
                <h3 class="mb-0 font-weight-normal">
                  Change Password
                </h3>
              </div>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Old Password"
                label-for="old-password"
                class="mb-2"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="oldPassword"
                  name="Old Password"
                >
                  <b-form-input
                    id="old-password"
                    v-model="oldPassword"
                    type="password"
                    placeholder="Enter Your Old Password"
                  />
                  <span
                    class="invalid-feedback d-block"
                  >
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="New Password"
                label-for="new-password"
                class="mb-2"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="newPassword"
                  name="New Password"
                >
                  <b-form-input
                    id="new-password"
                    v-model="newPassword"
                    type="password"
                    placeholder="Enter Your New Password"
                  />
                  <span
                    class="invalid-feedback d-block"
                  >
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>

              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Confirm Password"
                label-for="confirm-password"
                class="mb-2"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="confirmPassword"
                  name="Confirm Password"
                >
                  <b-form-input
                    id="confirm-password"
                    v-model="confirmPassword"
                    type="password"
                    placeholder="Enter Your Confirm Password"
                  />
                  <span
                    class="invalid-feedback d-block"
                  >
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>

              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="mt-3"
            >
              <b-button
                variant="primary"
                class="mr-1"
                type="submit"
              >
                Change
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      <!--/ form -->
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',

    }
  },
  methods: {
    updatePassword() {
      axios.post(`/auth/users/${this.userData.id}/change-password`, {
        old_password: this.oldPassword,
        password: this.newPassword,
        password_confirmation: this.confirmPassword,

      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Password Updated',
              icon: 'KeyIcon',
              variant: 'success',
              text: 'You have successfully changed your password',
            },
          })
          window.location.reload()
        }).catch(err => {
          if (err.response) {
            // console.log(err.response)
            const errPassOldPassword = err.response.data.errors.old_password ? err.response.data.errors.old_password[0] : ''
            const errPassNewPassword = err.response.data.errors.password ? err.response.data.errors.password[0] : ''
            const errPassConfirmPassword = err.response.data.errors.password ? err.response.data.errors.password[0] : ''
            this.$refs.form.setErrors({
              oldPassword: [errPassOldPassword],
              newPassword: [errPassNewPassword],
              confirmPassword: [errPassConfirmPassword],
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
</style>
